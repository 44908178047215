import { useLocalizationContext } from '@sharesight/gatsby-plugin-sharesight-localization';
import { Layout } from 'components/Layout';
import { Seo } from 'components/Seo';

import { HeroContainer, Section, SectionImage } from 'components/Container';

import { useContentfulLocale } from 'hooks/useContentfulLocale';
import { TrustPilot } from 'components/TrustPilot';
import { useGatsbyImage } from 'hooks/useGatsbyImage';
import type { LocalizedPageProps } from '@sharesight/gatsby-plugin-sharesight-localization';
import type { FC } from 'react';

export const Reviews: FC<LocalizedPageProps> = () => {
  const {
    translations: { freeUsers, countryInvestors },
  } = useContentfulLocale();

  const { currentLocale } = useLocalizationContext();

  /* Currently there is no US specific reviews, so we should use the global version instead */
  const localizeTag = !(currentLocale?.id === 'us');
  const tagId = currentLocale?.id === 'us' ? 'grid-global' : 'grid';

  return (
    <Layout>
      <Seo />

      <HeroContainer>
        <Section>
          <h1>Investors love Sharesight</h1>
          <p>
            Hear how a few of our {freeUsers} users became better investors since they started
            tracking their investments in Sharesight
          </p>
        </Section>

        <SectionImage
          image={useGatsbyImage({
            name: 'shared-page-assets/globe',
            alt: 'Graphic illustration of hands hugging world map',
            loading: 'eager',
          })}
          boxShadow={false}
        />
      </HeroContainer>
      <TrustPilot
        header={<h2>See what {countryInvestors} are saying on Trustpilot</h2>}
        height={950}
        tagId={tagId}
        localizeTag={localizeTag}
      />
    </Layout>
  );
};

export default Reviews;
